import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import NotFound from "../pages/NotFound";
import Login from "../components/Login";
import Home from "../pages/Home";


const Rotas = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login/>} />
                    <Route path="/" element={<Home/>} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Rotas;