import React, { useState, useContext} from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import api from "../api/api";

const initial = {
    username: '',
    password: '',
    empresa: ''
}

const initialFieldsError = {
    username: [],
    password: [],
    empresa: []
}

const Login: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [usuarioForm, setUsuarioForm] = useState(initial);
    const [empresas, setEmpresas] = useState([]);
    const [errors, setErrors] = useState(initialFieldsError);
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setUsuarioForm({...usuarioForm, [name]: value});
      }

    const handleExitLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        api.get(`/empresas_user/${usuarioForm.username}/`).then(res => {
            setEmpresas(res.data);
            if (res.data.length > 0) {
                setUsuarioForm({
                    ...usuarioForm,
                    empresa: res.data[0].id
                });
            }
        }).catch(err => {
            if (err.response.status === 400) {
                setErrors(err.response.data)
            }
            if (err.response.status === 403) {
                setShowError(true)
                setShowMessageError(err.response.data.detail)
            }
        })
    }

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (authContext) {
            await authContext.login(usuarioForm.username, usuarioForm.password, usuarioForm.empresa);
        }
    }

    return (
        <div style={{ alignItems: 'center', backgroundColor: '#e9ecef', 'display': 'flex', 
            flexDirection: 'column', 'height': '100vh', justifyContent: 'center' }}>
        
        <Container >
            <Row className="justify-content-center">
                <Col md={4}>
                    <Form onSubmit={handleLogin}>
                        <Card>
                            <Card.Header className="py-3 text-center fw-bold card-outline-primary">
                                Informe suas credenciais
                            </Card.Header>
                            <Card.Body>
                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>Usuário:</Form.Label>
                                        <Form.Control type="text" placeholder="Usuário" name="username" id="username"
                                            value={usuarioForm.username} onChange={handleChange}
                                            onBlur={handleExitLogin}
                                            autoFocus
                                        />
                                         {errors.username && (
                                            <ul className="errorfield">
                                                {errors.username.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Senha:</Form.Label>
                                        <Form.Control type="password" placeholder="Senha" value={usuarioForm.password} 
                                            name="password" id="password" onChange={handleChange} />
                                        {errors.password && (
                                            <ul className="errorfield">
                                                {errors.password.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}                                            
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="empresa">
                                        <Form.Label>Filiais:</Form.Label>
                                        <Form.Select aria-label="Default select example" 
                                            value={usuarioForm.empresa} onChange={handleChange} name="empresa" 
                                            id="empresa" >

                                           {empresas &&  empresas.map((emp: any) => {
                                                  return <option key={emp.id} value={emp.id}>{emp.nome}</option>
                                           })}

                                        </Form.Select>
                                        {errors.empresa && (
                                            <ul className="errorfield">
                                                {errors.empresa.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}                                              
                                    </Form.Group>
                           
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" type="submit">
                                        Entrar
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </Col>
            </Row>
            
        </Container>
        </div>
    );
}

export default Login;