import React from 'react';
import Rotas from './routes';


const App: React.FC = () => {
  return (
    <div>
      <Rotas />
    </div>
  );
}

export default App;
